
import RoutingComponent from "../common/RoutingComponent";
import TokenStorage from "../../utils/store/TokenStorage";
import appService from "../../service/app";
import { msalConfig } from "../../app/pages/login/authConfig";

export default class LoginPage extends RoutingComponent {
  constructor(props) {
    super(props);
    this._login = this._login.bind(this);
    this.redirectUrl = props.redirectUrl;
    this.onError = null;
  }

  render() {
    throw Error("Not Implemented");
  }

  get initialStatus() {
    return {
      fields: {
        account: "",
        password: "",
      },
      errors: {
        account: "",
        password: "",
        login: "",
      },
      loading: false,
    };
  }

  _showLoading = () => {
    this.setState({
      loading: true,
    });
  };

  _hideLoading = () => {
    this.setState({
      loading: false,
    });
  };

  // PMP Login

  get loginUser() {
    return {
      userName: this.state.fields["account"],
      password: this.state.fields["password"],
    };
  }

  _requestLogin() {
    this._showLoading();
    appService.login(this.loginUser, this._onSuccess, this.onError);
  }

  _onSuccess = (data) => {
    new TokenStorage().setAuthenticated(
      data.accessToken,
      data.refreshToken,
      data.userModel,
      data.roleModel
    );
    this._hideLoading();
    if (this.redirectUrl.toLowerCase().includes("login")) {
      const ProcedureListUrl = this.redirectUrl.replace(
        /login/gi,
        "ProcedureList"
      );
      window.location.href = ProcedureListUrl;
    } else {
      window.location.href = this.redirectUrl;
    }
  };

  _login() {
    this.setState({ errors: {} });
    if (!this._handleValidation()) {
      return;
    }

    this._requestLogin();
  }

  _reset = () => {
    this.setState({ fields: {} });
    this.setState({ errors: {} });
  };

  // ADFS Login

  storageAdfsAccessToken = (accessToken) => {
    new TokenStorage().adfsAccessToken(accessToken);
  };

  _adfsLogin = (email) => {
    const para = {
      userName: email,
    };
    appService.loginAdfs(para, this._onAdfsSuccess, this.onError);
  };

  _onAdfsSuccess = (data) => {
    new TokenStorage().setAuthenticated(
      data.accessToken,
      data.refreshToken,
      data.userModel,
      data.roleModel
    );
    window.location.href = msalConfig.auth.redirectUri;
  };
}

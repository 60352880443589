import UserModel from "../utils/model/UserModel";

const ExternalService = {
  FileService: {
    BaseUrl: process.env.FILE_SERVICE_API_ADDRESS,
    ProductKey: process.env.FILE_SERVICE_PRODUCTKEY,
    AzureAccountName: "proceduremanagement",
    ContainerName: process.env.FILE_SERVICE_CONTAINER_NAME,
    ProcedureFileFolder: "procedurefiles",
    SupportingFileFolder: "supportingfiles",
    AddFileFormData: "api/File/AddFileFormData",
    DeleteFile: "api/File/DeleteFile",
    DownloadFile: "api/File/DownLoadFile",
  },
};

const AppSetting = {
  webServer: window.location.protocol + "//" + window.location.host,
  endpoint: {
    common: process.env.BACK_END_API_ADDRESS,
  },

  urlPrefix: {
    // App
    login: { url: "api/App/Login", method: "Post", requireAuth: false },
    loginAdfs: { url: "api/App/LoginAdfs", method: "Post", requireAuth: true },
    menu: { url: "api/App/MenuAPI", method: "Post", requireAuth: true },
    route: { url: "api/App/RoutingAPI", method: "Post", requireAuth: true },
    accountAbbrList: {
      url: "api/Dictionary/GetAccountAbbreviations",
      method: "get",
      requireAuth: true,
    },
    accountStatusList: {
      url: "api/Dictionary/GetAccountStatus",
      method: "get",
      requireAuth: true,
    },
    taskGroupList: {
      url: "api/Dictionary/GetTaskGroups",
      method: "post",
      requireAuth: true,
    },
    taskStatusList: {
      url: "api/Dictionary/GetTaskStatus",
      method: "get",
      requireAuth: true,
    },
    categorieList: {
      url: "api/Dictionary/GetTaskGroupCategories",
      method: "post",
      requireAuth: true,
    },
    getSupportingFileList: {
      url: "api/SupportingFile/GetSupportingFileList",
      method: "post",
      requireAuth: true,
    },
    submitSupprotingFileForApproval: {
      url: "api/SupportingFile/SubmitForApproval",
      method: "post",
      requireAuth: true,
    },
    updateSupprotingFileForApproval: {
      url: "api/SupportingFile/UpdateForApproval",
      method: "post",
      requireAuth: true,
    },
    saveSupprotingFileAsDraft: {
      url: "api/SupportingFile/SaveAsDraft",
      method: "post",
      requireAuth: true,
    },
    submitForApproval: {
      url: "api/ProcedureFile/SubmitForApproval",
      method: "post",
      requireAuth: true,
    },
    updateForApproval: {
      url: "api/ProcedureFile/UpdateForApproval",
      method: "post",
      requireAuth: true,
    },
    saveAsDraft: {
      url: "api/ProcedureFile/SaveAsDraft",
      method: "post",
      requireAuth: true,
    },
    checkSectionLock: {
      url: "api/Procedure/CheckSectionLock",
      method: "post",
      requireAuth: true,
    },
    setSectionLock: {
      url: "api/Procedure/SetSectionLock",
      method: "get",
      requireAuth: true,
    },
    getProcedureFileSection: {
      url: "api/ProcedureFile/GetProcedureFileSection",
      method: "get",
      requireAuth: true,
    },
    getSupportingFileSection: {
      url: "api/SupportingFile/GetSupportingFileSection",
      method: "get",
      requireAuth: true,
    },
    checkSupportingFileEdit: {
      url: "api/SupportingFile/CheckSupportingFileEdit",
      method: "post",
      requireAuth: true,
    },
    getProcedureInfoSection: {
      url: "api/ProcedureInfo/GetProcedureInfoSection",
      method: "get",
      requireAuth: true,
    },
    getProcedureProcessStatus: {
      url: "api/Procedure/GetProcedureProcessStatus",
      method: "get",
      requireAuth: true,
    },
    getSignOffStepItem: {
      url: "api/SignOff/GetSignOffStepItem",
      method: "get",
      requireAuth: true,
    },
    getSignOffStepItemActived: {
      url: "api/SignOff/GetSignOffStepItemActived",
      method: "get",
      requireAuth: true,
    },
    signOffTriggerAction: {
      url: "api/SignOff/SignOffTriggerAction",
      method: "post",
      requireAuth: true,
    },
    addFile: {
      url:
        ExternalService.FileService.BaseUrl +
        ExternalService.FileService.AddFileFormData,
      method: "Post",
      requireAuth: true,
      ProductKey: ExternalService.FileService.ProductKey,
      AzureAccountName: ExternalService.FileService.AzureAccountName,
      ContainerName: ExternalService.FileService.ContainerName,
      ProcedureFileFolder: ExternalService.FileService.ProcedureFileFolder,
      SupportingFileFolder: ExternalService.FileService.SupportingFileFolder,
    },
    deleteFile: {
      url:
        ExternalService.FileService.BaseUrl +
        ExternalService.FileService.DeleteFile,
      method: "Delete",
      requireAuth: true,
      ProductKey: ExternalService.FileService.ProductKey,
    },
    downloadFile: {
      url:
        ExternalService.FileService.BaseUrl +
        ExternalService.FileService.DownloadFile,
      method: "get",
      requireAuth: true,
      ProductKey: ExternalService.FileService.ProductKey,
    },
    getProcedureFileList: {
      url: "api/ProcedureFile/GetProcedureFileList",
      method: "post",
      requireAuth: true,
    },
    getTaskInfo: {
      url: "api/Task/GetTaskInfo",
      method: "post",
      requireAuth: true,
    },
    getTaskInformation: {
      url: "api/Task/GetTaskInfo",
      method: "get",
      requireAuth: true,
    },
    submitProcedureInfo: {
      url: "api/ProcedureInfo/SubmitProcedureInfo",
      method: "Post",
      requireAuth: true,
    },
    getProcedureList: {
      url: "api/ProcedureList/GetProcedureList",
      method: "Post",
      requireAuth: true,
    },
    getProcedureFilter: {
      url: "api/ProcedureList/GetProcedureFilter",
      method: "Post",
      requireAuth: true,
    },
    getProcedureFolderStepItem: {
      url: "api/ProcedureFile/GetProcedureFileStepItem",
      method: "get",
      requireAuth: true,
    },
    getProcedureFolderStepItemActived: {
      url: "api/ProcedureFile/GetProcedureFileActivedStepItem",
      method: "get",
      requireAuth: true,
    },
    getPrimaryProcedureOwnerDDL: {
      url: "api/Dictionary/GetPrimaryProcedureOwnerDDL",
      method: "post",
      requireAuth: true,
    },
    getActionRequiredDDL: {
      url: "api/Dictionary/GetActionRequiredDDL",
      method: "get",
      requireAuth: true,
    },
    getActionTakenDDL: {
      url: "api/Dictionary/GetActionTakenDDL",
      method: "get",
      requireAuth: true,
    },
    getProcessCategoryDDL: {
      url: "api/Dictionary/GetProcessCategoryDDL",
      method: "get",
      requireAuth: true,
    },
    getPendingList: {
      url: "api/ToDoList/GetPendingList",
      method: "post",
      requireAuth: true,
    },
    getPendingListFilter: {
      url: "api/ToDoList/GetPendingListFilter",
      method: "post",
      requireAuth: true,
    },
    getCompletedList: {
      url: "api/ToDoList/GetCompletedList",
      method: "post",
      requireAuth: true,
    },
    getCompletedListFilter: {
      url: "api/ToDoList/GetCompletedListFilter",
      method: "post",
      requireAuth: true,
    },
    createProcedureTriggerApprovePageAction: {
      url: "api/ProcedureFile/CreateProcedureTriggerApprovePageAction",
      method: "post",
      requireAuth: true,
    },
    createProcedureTriggerRevisePageAction: {
      url: "api/ProcedureFile/CreateProcedureTriggerRevisePageAction",
      method: "post",
      requireAuth: true,
    },
    getAccountTaskGroupsCategoriesMapping: {
      url: "api/Dictionary/GetAccountTaskGroupsCategoriesMapping",
      method: "get",
      requireAuth: true,
    },
    assignPrimaryProcedureOwner: {
      url: "api/ProcedureInfo/AssignPrimaryProcedureOwner",
      method: "post",
      requireAuth: true,
    },
    getShareProcessStatus: {
      url: "api/ManageShareTask/GetShareProcessStatus",
      method: "post",
      requireAuth: true,
    },
    checkEdit: {
      url: "api/ManageShareTask/CheckEdit",
      method: "post",
      requireAuth: true,
    },
    getProcedureTaskList: {
      url: "api/ManageShareTask/GetProcedureTaskList",
      method: "post",
      requireAuth: true,
    },
    getTaskRowList: {
      url: "api/ManageShareTask/GetTaskRowList",
      method: "post",
      requireAuth: true,
    },
    getTaskRowInfo: {
      url: "api/ManageShareTask/GetTaskRowInfo",
      method: "post",
      requireAuth: true,
    },
    delTaskRowInfo: {
      url: "api/ManageShareTask/DelTaskRowInfo",
      method: "post",
      requireAuth: true,
    },
    checkSubmit: {
      url: "api/ManageShareTask/CheckSubmit",
      method: "post",
      requireAuth: true,
    },
    shareTaskSubmitForApproval: {
      url: "api/ManageShareTask/SubmitForApproval",
      method: "post",
      requireAuth: true,
    },
    getTaskTreePathDdl: {
      url: "api/ManageShareTask/GetTaskTreePathDdl",
      method: "post",
      requireAuth: true,
    },
    manageShareTaskTriggerAction: {
      url: "api/ManageShareTask/ManageShareTaskTriggerAction",
      method: "post",
      requireAuth: true,
    },
    getManageShareTaskStepItem: {
      url: "api/ManageShareTask/GetManageShareTaskStepItem",
      method: "get",
      requireAuth: true,
    },
    getManageShareTaskStepItemActived: {
      url: "api/ManageShareTask/GetShareTaskStepItemActived",
      method: "get",
      requireAuth: true,
    },
    checkManageShareTaskApprove: {
      url: "api/ManageShareTask/CheckApprove",
      method: "post",
      requireAuth: true,
    },
    checkHasPendingSignOff: {
      url: "api/ManageShareTask/CheckHasPendingSignOff",
      method: "post",
      requireAuth: true,
    },
    getAccountDDL: {
      url: "api/Dictionary/GetAccountDdl",
      method: "post",
      requireAuth: true,
    },
    checkPPOEdit: {
      url: "api/ProcedureInfo/CheckPPOEdit",
      method: "post",
      requireAuth: true,
    },
    checkPOEdit: {
      url: "api/ProcedureInfo/CheckPOEdit",
      method: "post",
      requireAuth: true,
    },
    applyPMPExcluded: {
      url: "api/PMPExcluded/ApplyPMPExcluded",
      method: "post",
      requireAuth: true,
    },
    getPrimaryProcedureOwnerStepItem: {
      url: "api/ProcedureInfo/GetPrimaryProcedureOwnerStepItem",
      method: "get",
      requireAuth: true,
    },
    getPrimaryProcedureOwnerStepItemActived: {
      url: "api/ProcedureInfo/GetPrimaryProcedureOwnerStepItemActived",
      method: "get",
      requireAuth: true,
    },
    changePrimaryProcedureOwnerTriggerStepAction: {
      url: "api/ProcedureInfo/ChangePrimaryProcedureOwnerTriggerStepAction",
      method: "post",
      requireAuth: true,
    },
    checkEditSection: {
      url: "api/ProcedureFile/CheckEditSection",
      method: "get",
      requireAuth: true,
    },
    getAllAccountAbbreviations: {
      url: "api/ProcedureList/GetAllAccountAbbreviations",
      method: "post",
      requireAuth: true,
    },
    getApplyPmpExcludedStepItem: {
      url: "api/PmpExcluded/GetApplyPmpExcludedStepItem",
      method: "get",
      requireAuth: true,
    },
    getApplyPmpExcludedStepItemActived: {
      url: "api/PmpExcluded/GetApplyPmpExcludedStepItemActived",
      method: "get",
      requireAuth: true,
    },
    applyPMPExcludedTriggerStepAction: {
      url: "api/PmpExcluded/ApplyPMPExcludedTriggerStepAction",
      method: "post",
      requireAuth: true,
    },
    checkApplyPmpExcludedEdit: {
      url: "api/PmpExcluded/CheckApplyPmpExcludedEdit",
      method: "post",
      requireAuth: true,
    },
    getToDoListAccountAbbreviations: {
      url: "api/ToDoList/GetToDoListAccountAbbreviations",
      method: "get",
      requireAuth: true,
    },
    submitDelegate: {
      url: "api/ToDoList/Delegate",
      method: "post",
      requireAuth: true,
    },
    delegateCheck: {
      url: "api/ToDoList/delegateCheck",
      method: "post",
      requireAuth: true,
    },
    createTLReviewProcedure: {
      url: "api/TlReview/CreateTlReviewProcedure",
      method: "post",
      requireAuth: true,
    },
    getTLReviewStepItem: {
      url: "api/TlReview/GetTlReviewStepItem",
      method: "get",
      requireAuth: true,
    },
    getTLReviewStepItemActived: {
      url: "api/TlReview/GetTlReviewStepItemActived",
      method: "get",
      requireAuth: true,
    },
    tlReviewTriggerStepAction: {
      url: "api/TlReview/TlReviewTriggerStepAction",
      method: "post",
      requireAuth: true,
    },
    checkTlReviewEdit: {
      url: "api/TlReview/CheckTlReviewEdit",
      method: "post",
      requireAuth: true,
    },
    getCreateSupportingFileStepItem: {
      url: "api/SupportingFile/GetCreateSupportingFileStepItem",
      method: "get",
      requireAuth: true,
    },
    getCreateSupportingFileStepItemActived: {
      url: "api/SupportingFile/GetCreateSupportingFileStepItemActived",
      method: "get",
      requireAuth: true,
    },
    createSupportingFileTriggerRevisePageAction: {
      url: "api/SupportingFile/CreateSupportingFileTriggerRevisePageAction",
      method: "post",
      requireAuth: true,
    },
    createSupportingFileTriggerApprovePageAction: {
      url: "api/SupportingFile/CreateSupportingFileTriggerApprovePageAction",
      method: "post",
      requireAuth: true,
    },
    updateSupportingFileTriggerRevisePageAction: {
      url: "api/SupportingFile/UpdateSupportingFileTriggerRevisePageAction",
      method: "post",
      requireAuth: true,
    },
    updateSupportingFileTriggerApprovePageAction: {
      url: "api/SupportingFile/UpdateSupportingFileTriggerApprovePageAction",
      method: "post",
      requireAuth: true,
    },
    supportingFileSubmitCheck: {
      url: "api/SupportingFile/CheckSubmit",
      method: "post",
      requireAuth: true,
    },
    supportingFileUpdateCheck: {
      url: "api/SupportingFile/UpdateCheckSubmit",
      method: "post",
      requireAuth: true,
    },
    getChangeMainTaskList: {
      url: "api/ChangeMainTask/GetChangeMainTaskList",
      method: "post",
      requireAuth: true,
    },
    checkChangeMainTaskSubmit: {
      url: "api/ChangeMainTask/CheckSubmit",
      method: "post",
      requireAuth: true,
    },
    changeMainTaskSubmitForApproval: {
      url: "api/ChangeMainTask/SubmitForApproval",
      method: "post",
      requireAuth: true,
    },
    getChangeMainTaskStepItem: {
      url: "api/ChangeMainTask/GetChangeMainTaskStepItem",
      method: "get",
      requireAuth: true,
    },
    changeMainTaskTriggerAction: {
      url: "api/ChangeMainTask/ChangeMainTaskTriggerAction",
      method: "post",
      requireAuth: true,
    },
    checkChangeMainTaskApprove: {
      url: "api/ChangeMainTask/CheckApprove",
      method: "post",
      requireAuth: true,
    },
    getMyRequestList: {
      url: "api/MyRequest/GetMyRequestList",
      method: "post",
      requireAuth: true,
    },
    getMyRequestFilter: {
      url: "api/MyRequest/GetMyRequestFilter",
      method: "post",
      requireAuth: true,
    },
    getMyRequestTopFilter: {
      url: "api/MyRequest/GetTopFilter",
      method: "get",
      requireAuth: true,
    },
    getProcessDetailData: {
      url: "api/MyRequest/GetProcessDetailData",
      method: "post",
      requireAuth: true,
    },
    getRevisionCategory: {
      url: "api/Dictionary/GetRevisionCategory",
      method: "get",
      requireAuth: true,
    },
    getClientMembers: {
      url: "api/Dictionary/GetClientMembers",
      method: "get",
      requireAuth: true,
    },
    jbStatusSubmitForApproval: {
      url: "api/JBStatus/SubmitForApproval",
      method: "post",
      requireAuth: true,
    },
    getApplyJBStatusStepItem: {
      url: "api/JBStatus/GetApplyJBStatusStepItem",
      method: "get",
      requireAuth: true,
    },
    getApplyJBStatusStepItemActived: {
      url: "api/JBStatus/GetApplyJBStatusStepItemActived",
      method: "get",
      requireAuth: true,
    },
    applyJBStatusTriggerStepAction: {
      url: "api/JBStatus/ApplyJBStatusTriggerStepAction",
      method: "post",
      requireAuth: true,
    },
    checkApplyJBStatusSubmit: {
      url: "api/JBStatus/CheckApplyJBStatusSubmit",
      method: "post",
      requireAuth: true,
    },
    getProcedureDocumentList: {
      url: "api/ProcedureList/GetProcedureDocumentList",
      method: "get",
      requireAuth: true,
    },
    getCoverSheet: {
      url: "api/ProcedureList/GetCoverSheet",
      method: "post",
      requireAuth: true,
    },
    getActiveInternalUserInfos: {
      url: "api/ShareDocument/GetActiveInternalUserInfos",
      method: "get",
      requireAuth: true,
    },
    shareDocument: {
      url: "api/ShareDocument/Share",
      method: "post",
      requireAuth: true,
    },
    downloadCheck: {
      url: "api/ShareDocument/DownloadCheck",
      method: "post",
      requireAuth: true,
    },
    checkPpoIsExisted: {
      url: "api/ProcedureInfo/CheckPpoIsExisted",
      method: "post",
      requireAuth: true,
    },
    getProcedureOwnerStepItem: {
      url: "api/ProcedureInfo/GetProcedureOwnerStepItem",
      method: "get",
      requireAuth: true,
    },
    getProcedureOwnerStepItemActived: {
      url: "api/ProcedureInfo/GetProcedureOwnerStepItemActived",
      method: "get",
      requireAuth: true,
    },
    checkPoIsExisted: {
      url: "api/ProcedureInfo/CheckPoIsExisted",
      method: "post",
      requireAuth: true,
    },
    changeProcedureOwnerTriggerStepAction: {
      url: "api/ProcedureInfo/ChangeProcedureOwnerTriggerStepAction",
      method: "post",
      requireAuth: true,
    },
    updateProcedureTriggerApprovePageAction: {
      url: "api/ProcedureFile/UpdateProcedureTriggerApprovePageAction",
      method: "post",
      requireAuth: true,
    },
    updateProcedureTriggerRevisePageAction: {
      url: "api/ProcedureFile/UpdateProcedureTriggerRevisePageAction",
      method: "post",
      requireAuth: true,
    },
    getCurrentProcedureHistory: {
      url: "api/ChangeHistory/GetCurrentProcedureHistory",
      method: "post",
      requireAuth: true,
    },
    getAllProcedureHistory: {
      url: "api/ChangeHistory/GetAllProcedureHistory",
      method: "post",
      requireAuth: true,
    },
    getTaskHistory: {
      url: "api/ChangeHistory/GetTaskHistory",
      method: "post",
      requireAuth: true,
    },
    getCategoryList: {
      url: "api/ChangeHistory/GetCategoryList",
      method: "get",
      requireAuth: true,
    },
    getProcessStatusList: {
      url: "api/ChangeHistory/GetProcessStatusList",
      method: "get",
      requireAuth: true,
    },
    getInitiatorList: {
      url: "api/ChangeHistory/GetInitiatorList",
      method: "get",
      requireAuth: true,
    },
    getCurrentProcessorFilter: {
      url: "api/ChangeHistory/GetCurrentProcessorFilter",
      method: "post",
      requireAuth: true,
    },
    getTriggerProcessFilter: {
      url: "api/ChangeHistory/GetTriggerProcessFilter",
      method: "post",
      requireAuth: true,
    },
    getHistoricalFiles: {
      url: "api/ProcedureFile/GetHistoricalFiles",
      method: "get",
      requireAuth: true,
    },
    checkProcedureDetailEdit: {
      url: "api/ProcedureList/CheckProcedureDetailEdit",
      method: "post",
      requireAuth: true,
    },
    exportProcedureList: {
      url: "api/ProcedureList/ExportProcedureList",
      method: "post",
      requireAuth: true,
    },
    checkExport: {
      url: "api/ProcedureList/CheckExport",
      method: "get",
      requireAuth: true,
    },
    checkCompletedToDoListExport: {
      url: "api/ToDoList/CheckCompletedToDoListExport",
      method: "get",
      requireAuth: true,
    },
    exportPendingTodoList: {
      url: "api/ToDoList/ExportPendingToDoList",
      method: "post",
      requireAuth: true,
    },
    exportCompletedTodoList: {
      url: "api/ToDoList/ExportCompletedToDoList",
      method: "post",
      requireAuth: true,
    },
    checkStepPermission: {
      url: "api/Procedure/CheckStepPermission",
      method: "post",
      requireAuth: true,
    },
    checkCreateProcedureFileSubmit: {
      url: "api/ProcedureFile/CheckCreateProcedureFileSubmit",
      method: "get",
      requireAuth: true,
    },
    checkUpdateProcedureFileSubmit: {
      url: "api/ProcedureFile/CheckUpdateProcedureFileSubmit",
      method: "get",
      requireAuth: true,
    },
    getDelegateToList: {
      url: "api/ToDoList/GetDelegateToList",
      method: "post",
      requireAuth: true,
    },
    checkPpoLostMembership: {
      url: "api/ProcedureInfo/CheckPpoLostMembership",
      method: "post",
      requireAuth: true,
    },
    getPagePermission: {
      url: "api/App/GetPagePermission",
      method: "post",
      requireAuth: true,
    },
    getFlowStepData: {
      url: "api/Step/GetFlowStepData",
      method: "get",
      requireAuth: true,
    },
    getGuidelines: {
      url: "api/App/GetGuidlines",
      method: "get",
      requireAuth: true,
    },
    registerFileShare: {
      url: process.env.BACK_END_API_ADDRESS + "api/FileShare/RegisterFileShare",
      method: "post",
      requireAuth: true,
    },
    getShowEditPermission: {
      url: "api/Procedure/GetShowEditPermission",
      method: "post",
      requireAuth: true,
    },
    getProcessInitiatorFilter: {
      url: "api/ChangeHistory/GetProcessInitiatorFilter",
      method: "post",
      requireAuth: true,
    },
  },
  defaultPage: "/ProcedureList",
  localStorageValue: {
    setAuthenticated: (accessToken, refreshToken, userModel, roleModel) => {
      AppSetting.localStorageValue.role.set(roleModel);
      AppSetting.localStorageValue.user.set(userModel);
      AppSetting.localStorageValue.token.accessToken.set(accessToken);
      AppSetting.localStorageValue.token.refreshToken.set(refreshToken);
      AppSetting.localStorageValue.isAuthenticated.set(true);
    },
    setUnauthenticated: () => {
      AppSetting.localStorageValue.role.clear();
      AppSetting.localStorageValue.user.clear();
      AppSetting.localStorageValue.token.clear();
      AppSetting.localStorageValue.isAuthenticated.set(false);
      AppSetting.localStorageValue.routes.clear();
      AppSetting.localStorageValue.menus.clear();
    },
    refreshAccessToken: (accessToken) => {
      AppSetting.localStorageValue.token.accessToken.set(accessToken);
    },
    refreshToken: (accessToken, refreshToken) => {
      AppSetting.localStorageValue.token.accessToken.set(accessToken);
      AppSetting.localStorageValue.token.refreshToken.set(refreshToken);
    },
    adfsAccessToken: (accessToken) => {
      AppSetting.localStorageValue.token.accessToken.set(accessToken);
    },
    role: {
      set: (param) => {
        localStorage.setItem("role", JSON.stringify(param));
      },
      get: () => {
        return JSON.parse(localStorage.getItem("role"));
      },
      clear: () => {
        localStorage.removeItem("role");
      },
    },
    user: {
      set: (param) => {
        localStorage.setItem("user", JSON.stringify(param));
      },
      get: () => {
        let result = Object.assign(
          new UserModel(),
          JSON.parse(localStorage.getItem("user"))
        );
        return result;
      },
      clear: () => {
        localStorage.removeItem("user");
      },
    },
    token: {
      accessToken: {
        get: () => {
          return localStorage.getItem("accessToken");
        },
        set: (param) => {
          localStorage.setItem("accessToken", param);
        },
        clear: () => {
          localStorage.removeItem("accessToken");
        },
      },
      refreshToken: {
        get: () => {
          return localStorage.getItem("refreshToken");
        },
        set: (param) => {
          localStorage.setItem("refreshToken", param);
        },
        clear: () => {
          localStorage.removeItem("refreshToken");
        },
      },
      clear: () => {
        AppSetting.localStorageValue.token.accessToken.clear();
        AppSetting.localStorageValue.token.refreshToken.clear();
      },
    },
    isAuthenticated: {
      get: () => {
        return localStorage.getItem("isAuthenticated");
      },
      set: (param) => {
        localStorage.setItem("isAuthenticated", param);
      },
      clear: () => {
        localStorage.removeItem("isAuthenticated");
      },
    },
    routes: {
      get: () => {
        return JSON.parse(localStorage.getItem("routes") ?? "[]");
      },
      set: (param) => {
        localStorage.setItem("routes", JSON.stringify(param));
      },
      clear: () => {
        localStorage.removeItem("routes");
      },
    },
    menus: {
      get: () => {
        return JSON.parse(localStorage.getItem("menus") ?? "[]");
      },
      set: (param) => {
        localStorage.setItem("menus", JSON.stringify(param));
      },
      clear: () => {
        localStorage.removeItem("menus");
      },
    },
    guidelines: {
      get: () => {
        return JSON.parse(localStorage.getItem("guidelines") ?? "[]");
      },
      set: (param) => {
        localStorage.setItem("guidelines", JSON.stringify(param));
      },
      clear: () => {
        localStorage.removeItem("guidelines");
      },
    },
  },
  sessionStorageValue: {
    setPermissionPrivateKey: (param) => {
      sessionStorage.setItem("permissionPrivateKey", param);
    },
    getPermissionPrivateKey: () => {
      return sessionStorage.getItem("permissionPrivateKey");
    },
    clearPermissionPrivateKey: () => {
      sessionStorage.removeItem("permissionPrivateKey");
    },
  },
};

export default AppSetting;

import React from "react";
import BaseComponent from "../../../common/BaseComponent";
import { Layout, Menu, Dropdown, Avatar } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import "./topMenu.scss";
import { useMsal } from "@azure/msal-react";
import { openAdfs, loginRequest } from "../../../../app/pages/login/authConfig";
import NavLog from "../../../../assets/image/PMPlogo-white.png";
import styles from "../leftMenu/style/index.module.scss";
import userGuideIcon from "../../../../assets/image/userGuide.svg";

const { Header } = Layout;

function MsalLogoutComponent(props) {
  const { logout, isHidden } = props;
  const { instance }  = useMsal();

  const adfsLogout = () => {
    logout();
    if(openAdfs) {
      instance.logout(loginRequest).catch((error) => console.log('loginRequest', error));
    } else {
      window.location.reload();
    }
  }

  if(isHidden) {
    return (
      <button 
        id="logout-btn"
        className="invisible-btn"
        onClick={adfsLogout}
      >
        Logout
      </button>
    )
  }

  return (
    <Menu.Item onClick={adfsLogout}>
      Logout <LogoutOutlined />
    </Menu.Item>
  );  
}

export default class TopMenu extends BaseComponent {
  constructor(props) {
    super(props);
  }

  _logout = () => {
    this.appSetting.localStorageValue.setUnauthenticated();
  };

  render() {
    const user = this.appSetting.localStorageValue.user.get();
    const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];

    if(!user?.data) {
      window.location.reload();
      return;
    }

    const menu = (
      <Menu>
        <Menu.Item disabled>{user.identityUserInfo.englishName}</Menu.Item>
        <MsalLogoutComponent
          logout={this._logout}
          isHidden={false}
        />
      </Menu>
    );

    const guideMenu = (
      <Menu>
        <Menu.Item>
          <a 
            href="/Procedure Management Platform Guideline.docx"
            download="Procedure Management Platform Guideline.docx"
          >
            Procedure Management Platform Guideline
          </a>
        </Menu.Item>
      </Menu>
    );

    const userGuideComponent = () => (
      <div className={styles.userGuideWrapper}>
        <img
          alt="User Guide"
          src={userGuideIcon}
        />
      </div>
    );
    
    return (
      <Header
        className="up-site-layout-background"
      >
        <div className="logo">
          <img className={styles.nav_logo} src={NavLog} />
        </div>
        <MsalLogoutComponent
          logout={this._logout}
          isHidden={true}
        />
        <Dropdown 
          overlay={guideMenu} 
          placement="bottomRight"
          overlayClassName="avatar-dropdown"
        >
          {userGuideComponent()}
        </Dropdown>
        <Dropdown overlay={menu} overlayClassName="avatar-dropdown">
          <Avatar
            style={{
              backgroundColor: ColorList[user.identityUserInfo.englishName.length % 4],
              verticalAlign: "middle",
              width: 32,
            }}
            gap={1}
          >
            {user.identityUserInfo.englishName[0]}
          </Avatar>
        </Dropdown>
      </Header>
    );
  }
}
